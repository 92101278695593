/**
 * 複数のコンポーネントから別々の設定でログを出力するためのサービス
 */
import { Injectable, Inject, Optional } from '@angular/core';
import { InjectionToken } from '@angular/core';

export interface LoggerConfig {
  enableDebug: boolean;
  enableInfo: boolean;
  enableWarn: boolean;
  enableError: boolean;
  prefix?: string;
  useTimestamp?: boolean;
}

export const DEFAULT_LOGGER_CONFIG: LoggerConfig = {
  enableDebug: true,
  enableInfo: true,
  enableWarn: true,
  enableError: true,
  useTimestamp: true,
};

export const LOGGER_CONFIG = new InjectionToken<LoggerConfig>('logger.config');

@Injectable()
export class ConfigurableLoggerService {
  private config: LoggerConfig;

  constructor(@Optional() @Inject(LOGGER_CONFIG) config: LoggerConfig | null) {
    // デフォルト設定とカスタム設定をマージ
    this.config = { ...DEFAULT_LOGGER_CONFIG, ...config };
  }

  // タイムスタンプを生成
  private getTimestamp(): string {
    return this.config.useTimestamp ? `[${new Date().toISOString()}] ` : '';
  }
  private formatMessage(message: string): string {
    // console.log('Format message - Current config:', this.config); // デバッグ追加
    const timestamp = this.getTimestamp();
    const prefix = this.config.prefix ? `[${this.config.prefix}] ` : '';
    const formattedMessage = `${timestamp}${prefix}${message}`;
    // console.log('Formatted message:', formattedMessage); // デバッグ追加
    return formattedMessage;
  }

  log(message?: any, ...args: unknown[]): void {
    console.log(this.formatMessage(message), ...args);
  }

  debug(message: string, ...args: unknown[]): void {
    // console.log('Debug method called1');
    // console.debug('Debug method called2');
    // console.log('Debug method - Current config:', this.config); // デバッグ追加
    if (this.config.enableDebug) {
      console.debug(this.formatMessage(message), ...args);
    }
    // else {
    //   console.log('Debug message suppressed due to config:', message); // デバッグ追加
    // }
  }

  info(message: string, ...args: unknown[]): void {
    // console.log('Info method - Current config:', this.config); // デバッグ追加
    if (this.config.enableInfo) {
      console.info(this.formatMessage(message), ...args);
    }
  }

  warn(message: string, ...args: unknown[]): void {
    // console.log('Info method - Current config:', this.config); // デバッグ追加
    if (this.config.enableWarn) {
      console.warn(this.formatMessage(message), ...args);
    }
  }

  error(message: string, ...args: unknown[]): void {
    // console.log('Info method - Current config:', this.config); // デバッグ追加
    if (this.config.enableError) {
      console.error(this.formatMessage(message), ...args);
    }
  }

  getConfig(): LoggerConfig {
    return { ...this.config };
  }
}
