import { Component } from '@angular/core';
import { AuthService, UserInfo } from '../../auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-info',
  template: `
    <ng-container *ngIf="loggedInUserInfo$ | async as userInfo">
      <div class="user-info-wrapper" *ngIf="userInfo.is_logged_in">
        <div class="company-name">会員ID: {{ userInfo.member_id }}</div>
        <div>会員名: {{ userInfo.member_name }}</div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .user-info-wrapper {
        color: orange;
      }
    `,
  ],
  // templateUrl: './user-info.component.html',
  // styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  loggedInUserInfo$: Observable<UserInfo | null> =
    this.authService.loggedInUserInfo$;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    // サービス側のログイン状態を取得するメソッドを実行(サービス内の会員情報loggedInUserInfo$も更新される)
    this.authService.checkAuthenticationStatus().subscribe();
  }
}
