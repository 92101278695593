import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  of,
  tap,
  timeout,
  retry,
  finalize,
  shareReplay,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ConfigurableLoggerService,
  LoggerConfig,
} from './configurable-logger.service';

@Injectable({
  providedIn: 'root',
})
export class CsrfTokenService {
  private logger: ConfigurableLoggerService;

  private csrfToken: string | null = null;
  private tokenRefreshInProgress: Observable<string> | null = null;

  constructor(private http: HttpClient) {
    const loggerConfig: LoggerConfig = {
      enableDebug: environment.debug, // 環境設定に基づいてデバッグログを制御
      enableInfo: true,
      enableWarn: true,
      enableError: true,
      prefix: 'CsrfTokenService',
      useTimestamp: true,
    };
    this.logger = new ConfigurableLoggerService(loggerConfig);
  }

  getCsrfToken(): Observable<string | null> {
    this.logger.debug('[CSRF] getCsrfToken called', {
      hasToken: !!this.csrfToken,
      isRefreshing: !!this.tokenRefreshInProgress,
    });

    if (this.csrfToken) {
      this.logger.debug('[CSRF] Returning existing token');
      return of(this.csrfToken);
    }

    if (this.tokenRefreshInProgress) {
      this.logger.debug('[CSRF] Returning in-progress request');
      return this.tokenRefreshInProgress;
    }

    this.logger.debug('[CSRF] Starting new token refresh');
    return this.refreshCsrfToken();
  }

  refreshCsrfToken(): Observable<string> {
    this.logger.debug('[CSRF] refreshCsrfToken started');

    this.tokenRefreshInProgress = this.http
      .get<{ csrf_token: string }>(
        `${environment.apiRoot}/auth/csrf-protection.php`,
        { headers: { 'X-Requested-With': 'XMLHttpRequest' } } // CORS対策
      )
      .pipe(
        tap(() => this.logger.debug('[CSRF] HTTP request initiated')),
        retry({ count: 2, delay: 1000 }), // リトライ間隔を1秒に設定
        timeout(15000),
        map((response) => {
          this.logger.debug('[CSRF] Response received:', response);
          if (!response.csrf_token) {
            throw new Error('No CSRF token in response');
          }
          return response.csrf_token;
        }),
        tap((token) => {
          this.logger.debug('[CSRF] Token successfully set');
          this.csrfToken = token;
        }),
        catchError((error) => {
          console.error('[CSRF] Error details:', {
            name: error.name,
            message: error.message,
            status: error?.status,
            statusText: error?.statusText,
          });
          throw error;
        }),
        finalize(() => {
          this.logger.debug('[CSRF] Request finalized');
          this.tokenRefreshInProgress = null;
        }),
        shareReplay(1) // 同じリクエストの結果を共有
      );

    return this.tokenRefreshInProgress;
  }

  clearCsrfToken(): void {
    this.logger.debug('[CSRF] Clearing token');
    this.csrfToken = null;
    this.tokenRefreshInProgress = null;
  }
}
