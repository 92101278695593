/**
 * POSTリクエストに対してCSRFトークンを自動的に付与するインターセプター
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CsrfTokenService } from '../services/csrf-token.service';
import { environment } from 'src/environments/environment';
import {
  ConfigurableLoggerService,
  LoggerConfig,
} from '../services/configurable-logger.service';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  private logger: ConfigurableLoggerService;

  constructor(private csrfTokenService: CsrfTokenService) {
    const loggerConfig: LoggerConfig = {
      enableDebug: environment.debug, // 環境設定に基づいてデバッグログを制御
      enableInfo: true,
      enableWarn: true,
      enableError: true,
      prefix: 'CsrfInterceptor',
      useTimestamp: true,
    };
    this.logger = new ConfigurableLoggerService(loggerConfig);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // POSTリクエストの場合のみCSRFトークンを追加
    if (request.method !== 'POST') {
      this.logger.debug(
        'Skipping CSRF token for non-POST request:',
        request.method,
        request.url
      );
      return next.handle(request);
    }

    this.logger.debug(
      'Processing POST request, getting CSRF token for:',
      request.url
    );

    return this.csrfTokenService.getCsrfToken().pipe(
      switchMap((token) => {
        if (token) {
          this.logger.debug('CSRF token obtained:', token);
          request = request.clone({
            setHeaders: {
              'X-CSRF-TOKEN': token,
            },
          });
        } else {
          this.logger.warn('No CSRF token available for request:', request.url);
        }
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        // CSRFトークンが無効な場合、新しいトークンを取得して再試行
        if (error.status === 403) {
          this.logger.warn(
            'CSRF token validation failed (403). Attempting to refresh token...'
          );
          return this.csrfTokenService.refreshCsrfToken().pipe(
            switchMap((newToken) => {
              this.logger.debug('New CSRF token obtained:', newToken);
              const newRequest = request.clone({
                setHeaders: {
                  'X-CSRF-TOKEN': newToken,
                },
              });
              return next.handle(newRequest);
            })
          );
        }
        this.logger.error('Request failed with error:', error);
        return throwError(() => error);
      })
    );
  }
}
