import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface UserInfo {
  member_id: string;
  member_name: string;
  is_logged_in: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiRoot = `${environment.apiRoot}`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private loggedInUserInfoSubject: BehaviorSubject<UserInfo | null> =
    new BehaviorSubject<UserInfo | null>(null);
  public loggedInUserInfo$ = this.loggedInUserInfoSubject.asObservable();

  constructor(private http: HttpClient) {}

  isJsonObjectEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  isOnlyDigits(str: string): boolean {
    return /^\d+$/.test(str);
  }

  isAlphanumeric(str: string): boolean {
    return /^[A-Za-z0-9]+$/.test(str);
  }

  checkAuthenticationStatus(): Observable<boolean> {
    return this.http
      .get<UserInfo>(
        `${this.apiRoot}/companies/public/read/read-get-logged-in-user-info.php`
      )
      .pipe(
        map((response: UserInfo) => {
          if (response.is_logged_in) {
            this.loggedInUserInfoSubject.next(response);
          } else {
            this.loggedInUserInfoSubject.next(null);
          }
          return response.is_logged_in;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error checking authentication status:', error);
          this.loggedInUserInfoSubject.next(null);
          return throwError(
            () => new Error('Failed to check authentication status')
          );
        })
      );
  }
  register(
    data: any,
    onSuccess: (message: string) => void,
    onError: (error: any) => void
  ): void {
    this.http
      .post(`${this.apiRoot}/auth/register.php`, data, {
        headers: this.headers,
      })
      .subscribe(
        (response: any) => {
          if (response.message) {
            console.log('Registration successful', response);
            onSuccess(response);
          } else if (response.errors) {
            console.error('AuthService : Registration error:', response);
            onError(response);
          }
        },
        (error: any) => {
          console.error('Registration error:', error);
          onError(error);
        }
      );
  }

  registerAdditionalMemberInfo(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiRoot}/companies/authenticated/update/auth-update-member-info.php`,
        data,
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('AuthService : Registration error:', error);
    return throwError(() => error);
  }

  registerAdditionalBillingRepresentativeInfo(
    data: any,
    onSuccess: (message: string) => void,
    onError: (error: any) => void
  ): void {
    this.http
      .post(
        `${this.apiRoot}/company_billing_representatives/authenticated/create-or-update/create-or-update-billing-representative-info.php`,
        data,
        {
          headers: this.headers,
          withCredentials: true,
        }
      )
      .subscribe(
        (response: any) => {
          if (response.message) {
            console.log(
              'AuthService : create-or-update-billing-representative-info.php successful',
              response
            );
            onSuccess(response);
          } else if (response.errors) {
            console.error(
              'AuthService : create-or-update-billing-representative-info.php error:',
              response
            );
            onError(response);
          }
        },
        (error: any) => {
          console.error('AuthService : Registration error:', error);
          onError(error);
        }
      );
  }

  registerAdditionalAdministratorInfo(
    data: any,
    onSuccess: (message: string) => void,
    onError: (error: any) => void
  ): void {
    this.http
      .post(
        `${this.apiRoot}/company_administrators/authenticated/create-or-update/create-or-update-administrator-info.php`,
        data,
        {
          headers: this.headers,
        }
      )
      .subscribe(
        (response: any) => {
          if (response.message) {
            console.log(
              'AuthService : registerAdditionalBillingRepresentativeInfo successful',
              response
            );
            onSuccess(response);
          } else if (response.errors) {
            console.error(
              'AuthService : registerAdditionalBillingRepresentativeInfo error:',
              response
            );
            onError(response);
          }
        },
        (error: any) => {
          console.error('AuthService : Registration error:', error);
          onError(error);
        }
      );
  }

  login(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiRoot}/auth/login.php`, credentials, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  logout(onSuccess: () => void, onError: (error: any) => void): void {
    this.http
      .post(
        `${this.apiRoot}/auth/logout.php`,
        {},
        { headers: this.headers, withCredentials: true }
      )
      .subscribe(
        (response) => {
          this.loggedInUserInfoSubject.next(null);
          console.log('logout() : Logged out successfully.', response);
          onSuccess();
        },
        (error) => {
          console.error('Logout error:', error);
          onError(error);
        }
      );
  }

  requestOTP(
    email: string,
    successCallback: (response: any) => void,
    errorCallback: (error: any) => void
  ): void {
    const postData = { email: email };

    this.http
      .post(`${this.apiRoot}/auth/request-otp.php`, postData, {
        headers: this.headers,
      })
      .subscribe({
        next: (response: any) => {
          successCallback(response);
        },
        error: (error: any) => {
          errorCallback(error);
        },
      });
  }
}
